<template>
  <div class="job-apply login-page">
    <div class="container">
      <div class="row">
        <div class="col-md-8 offset-md-2 col-sm-10 offset-sm-1">

          <form @submit.prevent="sendOTP" ref="form" class="job-apply-section shadow">
            <div class="row g-3">
              <div class="login-header mb-4 pb-3 text-center">
                <div class="login-logo mb-4">
                  <img src="/assets/forgotLogo.svg" alt="">
                </div>
                <h4 class="login-title"><b>Forgot password?</b></h4>
                <p>Login to get going with our recruitment process!</p>
              </div>
              <div class="col-md-12 mb-3">
                <label class="form-label" for="email">Email Address</label>
                <input type="text" id="email" v-model="credentials.email"
                       :class="from_errors.hasOwnProperty('email') ? 'form-control is-invalid' : 'form-control'"
                       placeholder="email@mail.com">
                <div class="invalid-feedback" v-if="from_errors.hasOwnProperty('email')">
                  {{ from_errors.email[0] }}
                </div>
              </div>
            </div>
            <div class="col-12 mt-4">
              <button type="submit" class="login-btn">
                Forgot password
              </button>
              <router-link to="/login" class="text-center forgot-password d-block mt-3">
                <u>Login</u>
              </router-link>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";

export default {
  name: "ForgotPassword",
  data: () => ({
    credentials: {
      email: ''
    },
    from_errors: {},
    reset: 0,
  }),
  methods: {
    sendOTP() {
      this.from_errors = {}
      axios.post('/send-otp/', {'email': this.credentials.email}).then(res => {
        this.$router.push({name: 'ResetPassword', params: this.credentials.email})
        console.log(res.data)
      }).catch(error => {
        this.from_errors = error.response.data
        console.log(error.response.data)
      })
    }
  }
}
</script>

<style scoped>

</style>